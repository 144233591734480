import TermsAndConditions from './TermsAndConidtions';

const TermsAndConditionsPopup = ({ setScrollableModal }) => {
  return (
    <section className="bg-[#00000061] fixed top-0 left-0 bottom-0 right-0 flex justify-center items-start px-[5%] z-[50000] overflow-y-auto">
      <article
        style={{
          height: 'fit-content',
        }}
        className="text-[var(--primary-color)] bg-[--background-color] shadow-md rounded-xl w-full max-w-5xl p-4 relative"
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setScrollableModal(false);
          }}
          className="text-3xl absolute top-3 right-4"
        >
          x
        </button>
        <TermsAndConditions modal={true} />
        <button
          className="btn ml-auto mr-0 block"
          onClick={(e) => {
            e.preventDefault();
            setScrollableModal(false);
          }}
        >
          Close
        </button>
      </article>
    </section>
  );
};
export default TermsAndConditionsPopup;
