import * as Yup from 'yup';

export const createJobPost = Yup.object({
  title: Yup.string().min(6).max(50).required('Please Enter Your Job Title'),
  location: Yup.string().required('Please Enter Job Location'),
  jobType: Yup.string().required('Please Select Job Type'),
  category: Yup.string().required('Please Enter category'),
  education: Yup.string().required('Please Enter Education'),
  salary: Yup.number(),
});

export const editJobPost = Yup.object({
  title: Yup.string().min(6).max(50).optional(),
  location: Yup.string().optional(),
  jobType: Yup.string().optional(),
  category: Yup.string().optional(),
  salary: Yup.number().optional(),
  education: Yup.string().optional(),
});

// admin login
export const adminLogin = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email address format'),
  password: Yup.string().required('Password is required'),
  checked: Yup.boolean(),
});

export const signUpBusiness = Yup.object({
  firstname: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, 'field must be without spaces/characters')
    .min(2)
    .max(15)
    .required('Please Enter Your Firstname'),
  lastname: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, 'field must be without spaces/characters')
    .min(2, 'lastname must be at least 2 characters')
    .max(15)
    .required('Please Enter Your Lastname'),

  phone: Yup.string().min(11).max(15).required('Phone number is required'),
  zipcode: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, 'field must be without spaces/characters')
    .required('ZipCode is required')
    .matches(
      /^(\d+)?(\d{4}|\d{5,})(?:-\d+)*$/,
      'ZIP code must be 4 or more digits'
    ),

  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email address format'),
  location: Yup.string()
    .min(5)
    .max(20)
    .required('Please Enter Location')
    .test(
      'no-consecutive-empty-strings',
      'Location must not contain consecutive empty strings',
      (value) => {
        return !/(^|\S)(\s{2,})(?!\S)/.test(value);
      }
    ),
  jobType: Yup.string().required('Please Select Job Type'),
  companyname: Yup.string()
    .required('Please Enter Company Name')
    .test(
      'no-consecutive-empty-strings',
      'field must not contain consecutive empty strings',
      (value) => {
        return !/(^|\S)(\s{2,})(?!\S)/.test(value);
      }
    ),
  positionHiring: Yup.string()
    .min(5)
    .max(20)
    .required('Please Enter Position for hiring')
    .test(
      'no-consecutive-empty-strings',
      'field must not contain consecutive empty strings',
      (value) => {
        return !/(^|\S)(\s{2,})(?!\S)/.test(value);
      }
    ),
  additionalInformation: Yup.string().required(
    'Please Enter Additional Information'
  ),
  date: Yup.date().required('Date is required'),
  time: Yup.string().required('Time is required'),
});
// FORGET PASSWARD
export const forgetPassword = Yup.object({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

// user signup

export const userSignup = Yup.object({
  firstName: Yup.string()
    .min(2)
    .max(15)
    .required('Please Enter Your Firstname'),
  lastName: Yup.string().min(2).max(15).required('Please Enter Your Lastname'),
  phoneNumber: Yup.string().required('Phone number is required'),
  zip: Yup.string()
    .required('ZipCode is required')
    .matches(
      /^(\d+)?(\d{4}|\d{5,})(?:-\d+)*$/,
      'ZIP code must be 4 or more digits, with an optional hyphen (e.g., 12345 or 12345-6789)'
    ),

  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email address format'),
  verifyEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .required('Verify Email is required'),
  password: Yup.string()
    .required('Please Enter Password')
    .min(4, 'Password must be at least 4 characters')
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{4,})/,
      'Password must contain at least one uppercase letter, one number, and one special character'
    ),
  country: Yup.string().required('Please Select your Country'),
  state: Yup.string().required('Please Select your state'),
  eligibility: Yup.string().required('Please Enter Position for hiring'),
});

export const userEdit = Yup.object({
  firstName: Yup.string()
    .min(2)
    .max(15)
    .required('Please Enter Your Firstname'),
  lastName: Yup.string().min(2).max(15).required('Please Enter Your Lastname'),
  phoneNumber: Yup.string().required('Phone number is required'),
  zip: Yup.string()
    .required('ZipCode is required')
    .matches(
      /^(\d+)?(\d{4}|\d{5,})(?:-\d+)*$/,
      'ZIP code must be 4 or more digits, with an optional hyphen (e.g., 12345 or 12345-6789)'
    ),

  // email: Yup.string().email().required("Email is required"),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email address format'),
  country: Yup.string().required('Please Select your Country'),
  state: Yup.string().required('Please Select your state'),
});

export const contactUsSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,14}$/, 'Enter a valid phone number'), // Regex for E.164 format
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  company: Yup.string().required('Company is required'),
  jobTitle: Yup.string().required('Job title is required'),
  country: Yup.string().required('Country is required'),
  message: Yup.string().required('Message is required'),
});
